import { Link } from "react-router-dom";

const Blog = () => {
  return (
    <>
      {/* <!-- Page Header Start --> */}
      <div class="container-fluid page-header py-5">
        <div class="container text-center py-5">
          <h1 class="display-2 text-white mb-4 animated slideInDown">
            Our Blog
          </h1>
          <nav aria-label="breadcrumb animated slideInDown">
            <ol class="breadcrumb justify-content-center mb-0">
              <li class="breadcrumb-item">
                <Link to="/">Home</Link>
              </li>
              <li class="breadcrumb-item">
                <Link to="/blog">Pages</Link>
              </li>
              <li class="breadcrumb-item" aria-current="page">
                Blog
              </li>
            </ol>
          </nav>
        </div>
      </div>
      {/* <!-- Page Header End --> */}

      {/* <!-- Fact Start --> */}
      <div class="container-fluid bg-secondary py-5">
        <div class="container">
          <div class="row">
            <div class="col-lg-3 wow fadeIn" data-wow-delay=".1s">
              <div class="d-flex counter">
                <h1 class="me-3 text-primary counter-value">99</h1>
                <h5 class="text-white mt-1">
                  Success in getting happy customer
                </h5>
              </div>
            </div>
            <div class="col-lg-3 wow fadeIn" data-wow-delay=".3s">
              <div class="d-flex counter">
                <h1 class="me-3 text-primary counter-value">25</h1>
                <h5 class="text-white mt-1">
                  Thousands of successful business
                </h5>
              </div>
            </div>
            <div class="col-lg-3 wow fadeIn" data-wow-delay=".5s">
              <div class="d-flex counter">
                <h1 class="me-3 text-primary counter-value">120</h1>
                <h5 class="text-white mt-1">Total clients who love HighTech</h5>
              </div>
            </div>
            <div class="col-lg-3 wow fadeIn" data-wow-delay=".7s">
              <div class="d-flex counter">
                <h1 class="me-3 text-primary counter-value">5</h1>
                <h5 class="text-white mt-1">
                  Stars reviews given by satisfied clients
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Fact End --> */}

      {/* <!-- Blog Start --> */}
      <div class="container-fluid blog py-5 mb-5">
        <div class="container">
          <div
            class="text-center mx-auto pb-5 wow fadeIn"
            data-wow-delay=".3s"
            style={{ maxWidth: "600px" }}
          >
            <h5 class="text-primary">Our Blog</h5>
            <h1>Latest Blog & News</h1>
          </div>
          <div class="row g-5 justify-content-center">
            <div class="col-lg-6 col-xl-4 wow fadeIn" data-wow-delay=".3s">
              <div class="blog-item position-relative bg-light rounded">
                <img
                  src="img/blog-1.jpg"
                  class="img-fluid w-100 rounded-top"
                  alt=""
                />
                <span
                  class="position-absolute px-4 py-3 bg-primary text-white rounded"
                  style={{ top: "-28px", right: "20px" }}
                >
                  Web Design
                </span>
                <div
                  class="blog-btn d-flex justify-content-between position-relative px-3"
                  style={{ marginTop: "-75px" }}
                >
                  <div class="blog-icon btn btn-secondary px-3 rounded-pill my-auto">
                    <Link to="/blog" class="btn text-white">
                      Read More
                    </Link>
                  </div>
                  <div class="blog-btn-icon btn btn-secondary px-4 py-3 rounded-pill ">
                    <div class="blog-icon-1">
                      <p class="text-white px-2">
                        Share<i class="fa fa-arrow-right ms-3"></i>
                      </p>
                    </div>
                    <div class="blog-icon-2">
                      <Link to="" class="btn me-1">
                        <i class="fab fa-facebook-f text-white"></i>
                      </Link>
                      <Link to="" class="btn me-1">
                        <i class="fab fa-twitter text-white"></i>
                      </Link>
                      <Link to="" class="btn me-1">
                        <i class="fab fa-instagram text-white"></i>
                      </Link>
                    </div>
                  </div>
                </div>
                <div
                  class="blog-content text-center position-relative px-3"
                  style={{ marginTop: "-25px" }}
                >
                  <img
                    src="img/admin.jpg"
                    class="img-fluid rounded-circle border border-4 border-white mb-3"
                    alt=""
                  />
                  <h5 class="">Salini Sharma</h5>
                  <span class="text-secondary">24 March 2023</span>
                  <p class="py-2">
                    "Thank you for the fantastic web design! The layout is
                    clean, and everything functions perfectly."
                  </p>
                </div>
                <div class="blog-coment d-flex justify-content-between px-4 py-2 border bg-primary rounded-bottom">
                  <Link to="" class="text-white">
                    <small>
                      <i class="fas fa-share me-2 text-secondary"></i>233 Share
                    </small>
                  </Link>
                  <Link to="" class="text-white">
                    <small>
                      <i class="fa fa-comments me-2 text-secondary"></i>5
                      Comments
                    </small>
                  </Link>
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-xl-4 wow fadeIn" data-wow-delay=".5s">
              <div class="blog-item position-relative bg-light rounded">
                <img
                  src="img/blog-2.jpg"
                  class="img-fluid w-100 rounded-top"
                  alt=""
                />
                <span
                  class="position-absolute px-4 py-3 bg-primary text-white rounded"
                  style={{ top: "-28px", right: "20px" }}
                >
                  Development
                </span>
                <div
                  class="blog-btn d-flex justify-content-between position-relative px-3"
                  style={{ marginTop: "-75px" }}
                >
                  <div class="blog-icon btn btn-secondary px-3 rounded-pill my-auto">
                    <Link to="/blog" class="btn text-white ">
                      Read More
                    </Link>
                  </div>
                  <div class="blog-btn-icon btn btn-secondary px-4 py-3 rounded-pill ">
                    <div class="blog-icon-1">
                      <p class="text-white px-2">
                        Share<i class="fa fa-arrow-right ms-3"></i>
                      </p>
                    </div>
                    <div class="blog-icon-2">
                      <Link to="" class="btn me-1">
                        <i class="fab fa-facebook-f text-white"></i>
                      </Link>
                      <Link to="" class="btn me-1">
                        <i class="fab fa-twitter text-white"></i>
                      </Link>
                      <Link to="" class="btn me-1">
                        <i class="fab fa-instagram text-white"></i>
                      </Link>
                    </div>
                  </div>
                </div>
                <div
                  class="blog-content text-center position-relative px-3"
                  style={{ marginTop: "-25px" }}
                >
                  <img
                    src="img/admin.jpg"
                    class="img-fluid rounded-circle border border-4 border-white mb-3"
                    alt=""
                  />
                  <h5 class="">By Daniel Martin</h5>
                  <span class="text-secondary">29 April 2024</span>
                  <p class="py-2">
                    "We're impressed with the development work; the website is
                    user-friendly and looks professional."
                  </p>
                </div>
                <div class="blog-coment d-flex justify-content-between px-4 py-2 border bg-primary rounded-bottom">
                  <Link to="" class="text-white">
                    <small>
                      <i class="fas fa-share me-2 text-secondary"></i>134 Share
                    </small>
                  </Link>
                  <Link to="" class="text-white">
                    <small>
                      <i class="fa fa-comments me-2 text-secondary"></i>8
                      Comments
                    </small>
                  </Link>
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-xl-4 wow fadeIn" data-wow-delay=".7s">
              <div class="blog-item position-relative bg-light rounded">
                <img
                  src="img/blog-3.jpg"
                  class="img-fluid w-100 rounded-top"
                  alt=""
                />
                <span
                  class="position-absolute px-4 py-3 bg-primary text-white rounded"
                  style={{ top: "-28px", right: "20px" }}
                >
                  Mobile App
                </span>
                <div
                  class="blog-btn d-flex justify-content-between position-relative px-3"
                  style={{ marginTop: "-75px" }}
                >
                  <div class="blog-icon btn btn-secondary px-3 rounded-pill my-auto">
                    <Link to="/blog" class="btn text-white ">
                      Read More
                    </Link>
                  </div>
                  <div class="blog-btn-icon btn btn-secondary px-4 py-3 rounded-pill ">
                    <div class="blog-icon-1">
                      <p class="text-white px-2">
                        Share<i class="fa fa-arrow-right ms-3"></i>
                      </p>
                    </div>
                    <div class="blog-icon-2">
                      <Link to="" class="btn me-1">
                        <i class="fab fa-facebook-f text-white"></i>
                      </Link>
                      <Link to="" class="btn me-1">
                        <i class="fab fa-twitter text-white"></i>
                      </Link>
                      <Link to="" class="btn me-1">
                        <i class="fab fa-instagram text-white"></i>
                      </Link>
                    </div>
                  </div>
                </div>
                <div
                  class="blog-content text-center position-relative px-3"
                  style={{ marginTop: "-25px" }}
                >
                  <img
                    src="img/admin.jpg"
                    class="img-fluid rounded-circle border border-4 border-white mb-3"
                    alt=""
                  />
                  <h5 class="">By Daniel Martin</h5>
                  <span class="text-secondary">14 sept 2024</span>
                  <p class="py-2">
                    "Absolutely love the new design; it's modern and
                    user-friendly!"
                  </p>
                </div>
                <div class="blog-coments d-flex justify-content-between px-4 py-2 border bg-primary rounded-bottom">
                  <Link to="" class="text-white">
                    <small>
                      <i class="fas fa-share me-2 text-secondary"></i>102 Share
                    </small>
                  </Link>
                  <Link to="" class="text-white">
                    <small>
                      <i class="fa fa-comments me-2 text-secondary"></i>3
                      Comments
                    </small>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Blog End --> */}

      {/* <!-- Back to Top --> */}
      <button
        className="btn btn-secondary btn-square rounded-circle back-to-top"
        onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
      >
        <i className="fa fa-arrow-up text-white"></i>
      </button>
    </>
  );
};

export default Blog;
