import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      {/* <!-- Footer Start --> */}
      <div
        class="container-fluid footer bg-dark wow fadeIn"
        data-wow-delay=".3s"
      >
        <div class="container pt-2 pb-4">
          <div class="row g-5">
            <div class="col-lg-5 col-md-8">
               <Link to="#">
                <h1 class="text-white fw-bold d-block">
                  Digital<span class="text-secondary">add</span>world
                  <span class="text-secondary">Tech</span>{" "}
                </h1>
              </Link>
              <p class="mt-4 text-light">
                We at Digitaladdworld Tech are proud to say that we are a
                top-notch digital marketing agency fully committed to providing
                innovative IT Solutions. Even though our quest commenced on
                January 16, 2023, we have quite quickly become a go-to agency
                for the businesses that need support in the elevation of their
                online presence.
              </p>
              <div class="d-flex hightech-link">
                <Link
                  to="https://www.facebook.com/profile.php?id=61551668379454"
                  class="btn-light nav-fill btn btn-square rounded-circle me-2"
                >
                  <i class="fab fa-facebook-f text-primary"></i>
                </Link>
                <Link
                  to=""
                  class="btn-light nav-fill btn btn-square rounded-circle me-2"
                >
                  <i class="fab fa-twitter text-primary"></i>
                </Link>
                <Link
                  to=""
                  class="btn-light nav-fill btn btn-square rounded-circle me-2"
                >
                  <i class="fab fa-instagram text-primary"></i>
                </Link>
                <Link
                  to=""
                  class="btn-light nav-fill btn btn-square rounded-circle me-0"
                >
                  <i class="fab fa-linkedin-in text-primary"></i>
                </Link>
              </div>
            </div>
            {/* <div class="col-lg-3 col-md-6">
                         <Link to="#" class="h3 text-secondary">Short Link</a>
                        <div class="mt-4 d-flex flex-column short-link">
                            <Link  to="" class="mb-2 text-white"><i class="fas fa-angle-right text-secondary me-2"></i>About us</a>
                            <Link  to="" class="mb-2 text-white"><i class="fas fa-angle-right text-secondary me-2"></i>Contact us</a>
                            <Link  to="" class="mb-2 text-white"><i class="fas fa-angle-right text-secondary me-2"></i>Our Services</a>
                            <Link  to="" class="mb-2 text-white"><i class="fas fa-angle-right text-secondary me-2"></i>Our Projects</a>
                            <Link  to="" class="mb-2 text-white"><i class="fas fa-angle-right text-secondary me-2"></i>Latest Blog</a>
                        </div>
                    </div> */}
            <div class="col-lg-3 col-md-6">
              <Link  to="" class="h3 text-secondary">
                Our Services
              </Link>
              <div class="mt-4 d-flex flex-column help-link">
                <Link  to="/service" class="mb-2 text-white">
                  <i class="fas fa-angle-right text-secondary me-2"></i>Servies
                </Link>
                <Link  to="/project" class="mb-2 text-white">
                  <i class="fas fa-angle-right text-secondary me-2"></i>Projects
                </Link>
                <Link  to="/about" class="mb-2 text-white">
                  <i class="fas fa-angle-right text-secondary me-2"></i>About
                </Link>
                <Link  to="/contact" class="mb-2 text-white">
                  <i class="fas fa-angle-right text-secondary me-2"></i>Contact
                </Link>
              </div>
            </div>
            <div class="col-lg-3 col-md-6">
              <Link  to="" class="h3 text-secondary">
                Contact Us
              </Link>
              <div class="text-white mt-4 d-flex flex-column contact-link">
                <Link  to="" class="pb-3 text-light border-bottom border-primary">
                  <i class="fas fa-map-marker-alt text-secondary me-2"></i>{" "}
                  Shankar Garden , Vikaspuri, Delhi, India. 110018
                </Link>
                <Link  to="https://www.google.com/maps/search/?api=1&query=26.850811,75.790472"
                  target="_blank"
                  class="pb-3 text-light border-bottom border-primary"
                >
                  <i class="fas fa-map-marker-alt text-secondary me-2"></i>{" "}
                  A-124 Near by Sanganer Raod, Dadu Dayal Nagar, Kalyanpura,
                  Sanganer, Jaipur-302020, Rajasthan{" "}
                </Link>
                {/* <Link  to="" class="py-3 text-light border-bottom border-primary"><i class="fas fa-phone-alt text-secondary me-2"></i> + 0141-4084426 </Link> */}
                <Link  to="mailto:hrteam@digitaladdworld.in"
                  class="py-3 text-light border-bottom border-primary"
                >
                  <i class="fas fa-envelope text-secondary me-2"></i>{" "}
                  hrteam@digitaladdworld.in{" "}
                </Link>
              </div>
            </div>
          </div>
          <hr class="text-light mt-5 mb-4" />
          <div class="row">
            <div class="col-md-12 text-center text-md-start">
              <span class="text-light">
                <Link  to="" class="text-secondary">
                  {" "}
                  © 2024 digitaladdworld
                </Link>{" "}
                , All rights reserved. hrteam@digitaladdworld.in
              </span>
              {/* © 2024 [Your Company Name]. All rights reserved. | [Your Company Tagline] | [Your Contact Information] */}
            </div>
            {/* <div class="col-md-6 text-center text-md-end">
                        <span class="text-light">Designed By<Link  to="https://htmlcodex.com" class="text-secondary">HTML Codex</a> Distributed By <Link  to="https://themewagon.com">ThemeWagon</a></span>
                    </div> */}
          </div>
        </div>
      </div>
      {/* <!-- Footer End --> */}
    </>
  );
};
export default Footer;
