import { Link } from "react-router-dom";

const Contact = () => {
  return (
    <>
      {/* <!-- Page Header Start --> */}
      <div class="container-fluid page-header py-5">
        <div class="container text-center py-5">
          <h1 class="display-2 text-white mb-4 animated slideInDown">
            Contact Us
          </h1>
          <nav aria-label="breadcrumb animated slideInDown">
            <ol class="breadcrumb justify-content-center mb-0">
              <li class="breadcrumb-item">
                <Link to="/">Home</Link>
              </li>
              <li class="breadcrumb-item">
                <Link to="/contact">Pages</Link>
              </li>
              <li class="breadcrumb-item" aria-current="page">
                Contact
              </li>
            </ol>
          </nav>
        </div>
      </div>
      {/* <!-- Page Header End --> */}
      {/* <!-- Fact Start --> */}
      <div class="container-fluid bg-secondary py-5">
        <div class="container">
          <div class="row">
            <div class="col-lg-3 wow fadeIn" data-wow-delay=".1s">
              <div class="d-flex counter">
                <h1 class="me-3 text-primary counter-value">99</h1>
                <h5 class="text-white mt-1">
                  Success in getting happy customer
                </h5>
              </div>
            </div>
            <div class="col-lg-3 wow fadeIn" data-wow-delay=".3s">
              <div class="d-flex counter">
                <h1 class="me-3 text-primary counter-value">25</h1>
                <h5 class="text-white mt-1">
                  Thousands of successful business
                </h5>
              </div>
            </div>
            <div class="col-lg-3 wow fadeIn" data-wow-delay=".5s">
              <div class="d-flex counter">
                <h1 class="me-3 text-primary counter-value">120</h1>
                <h5 class="text-white mt-1">Total clients who love HighTech</h5>
              </div>
            </div>
            <div class="col-lg-3 wow fadeIn" data-wow-delay=".7s">
              <div class="d-flex counter">
                <h1 class="me-3 text-primary counter-value">5</h1>
                <h5 class="text-white mt-1">
                  Stars reviews given by satisfied clients
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Fact End --> */}

      {/* <!-- Contact Start --> */}
      <div class="container-fluid py-5 mt-5">
        <div class="container py-5">
          <div
            class="text-center mx-auto pb-5 wow fadeIn"
            data-wow-delay=".3s"
            style={{ maxWidth: "600px" }}
          >
            <h5 class="text-primary">Get In Touch</h5>
            <h1 class="mb-3">Contact for any query</h1>
            <p class="mb-2">
              The contact form is currently active. Get a functional and working
              contact form with React . Just copy and paste the files, add a
              little code and you're done. <Link to="/">Download Now</Link>.
            </p>
          </div>
          <div class="contact-detail position-relative p-5">
            <div class="row g-5 mb-5 justify-content-center">
              <div class="col-xl-4 col-lg-6 wow fadeIn" data-wow-delay=".3s">
                <div class="d-flex bg-light p-3 rounded">
                  <div
                    class="flex-shrink-0 btn-square bg-secondary rounded-circle"
                    style={{ width: "50px", height: "50px" }} >
                    <i class="fas fa-map-marker-alt text-white"></i>
                  </div>
                  <div class="ms-3">
                    <h4 class="text-primary">Address</h4>
                    <Link
                      to="https://www.google.com/maps/search/?api=1&query=26.850811,75.790472"
                      target="_blank"
                      class="h5"
                    >
                      {" "}
                      Sanganer, Jaipur-302020, Rajasthan{" "}
                    </Link>
                  </div>
                </div>
              </div>
              <div class="col-xl-4 col-lg-6 wow fadeIn" data-wow-delay=".5s">
                <div class="d-flex bg-light p-3 rounded">
                  <div
                    class="flex-shrink-0 btn-square bg-secondary rounded-circle"
                    style={{ width: "50px", height: "50px" }}
                  >
                    <i class="fa fa-phone text-white"></i>
                  </div>
                  <div class="ms-3">
                    <h4 class="text-primary">Call Us</h4>
                    <Link class="h5" to="tel:+ 0141-4084426" target="_blank">
                      {" "}
                      + 0141-4084426
                    </Link>
                  </div>
                </div>
              </div>
              <div class="col-xl-4 col-lg-6 wow fadeIn" data-wow-delay=".7s">
                <div class="d-flex bg-light p-3 rounded">
                  <div
                    class="flex-shrink-0 btn-square bg-secondary rounded-circle"
                    style={{ width: "50px", height: "50px" }}
                  >
                    <i class="fa fa-envelope text-white"></i>
                  </div>
                  <div class="ms-3">
                    <h4 class="text-primary">Email Us</h4>
                    <Link
                      class="h5"
                      to="mailto:hrteam@digitaladdworld.in"
                      target="_blank"
                    >
                      {" "}
                      hrteam@digitaladdworld.in
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div class="row g-5">
              <div class="col-lg-6 wow fadeIn" data-wow-delay=".3s">
                <div class="p-3 h-100 rounded contact-map">
                  <iframe
                    class="rounded w-100 h-100"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3559.1178606767877!2d75.79047297401118!3d26.850811064344878!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x396db6f7b0f3c5d7%3A0x713d5b96f9bb61cf!2sA-124%2C%20Near%20Sanganer%20Road%2C%20Dadu%20Dayal%20Nagar%2C%20Kalyanpura%2C%20Sanganer%2C%20Jaipur%2C%20Rajasthan%20302020!5e0!3m2!1sen!2sin!4v1696517425785!5m2!1sen!2sin"
                    style={{ border: "0" }}
                    allowfullscreen=""
                    loading="lazy"
                    referrerpolicy="no-referrer-when-downgrade"
                  ></iframe>
                </div>
              </div>
              <div class="col-lg-6 wow fadeIn" data-wow-delay=".5s">
                <div class="p-3 rounded contact-form">
                  <div class="mb-4">
                    <input
                      type="text"
                      class="form-control border-0 py-3"
                      placeholder="Your Name"
                    />
                  </div>
                  <div class="mb-4">
                    <input
                      type="email"
                      class="form-control border-0 py-3"
                      placeholder="Your Email"
                    />
                  </div>
                  <div class="mb-4">
                    <input
                      type="text"
                      class="form-control border-0 py-3"
                      placeholder="Project"
                    />
                  </div>
                  <div class="mb-4">
                    <textarea
                      class="w-100 form-control border-0 py-3"
                      rows="6"
                      cols="10"
                      placeholder="Message"
                    ></textarea>
                  </div>
                  <div class="text-start">
                    <button
                      class="btn bg-primary text-white py-3 px-3"
                      type="button"
                    >
                      Send Message
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Contact End --> */}

      {/* <!-- Back to Top --> */}
      <button
        className="btn btn-secondary btn-square rounded-circle back-to-top"
        onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
      >
        <i className="fa fa-arrow-up text-white"></i>
      </button>
    </>
  );
};

export default Contact;
