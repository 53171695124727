import { Link } from "react-router-dom";

const Project = () => {
  return (
    <>
      {/* <!-- Page Header Start --> */}
      <div class="container-fluid page-header py-5">
        <div class="container text-center py-5">
          <h1 class="display-2 text-white mb-4 animated slideInDown">
            Projects
          </h1>
          <nav aria-label="breadcrumb animated slideInDown">
            <ol class="breadcrumb justify-content-center mb-0">
              <li class="breadcrumb-item">
                <Link to="/">Home</Link>
              </li>
              <li class="breadcrumb-item">
                <Link to="/project">Pages</Link>
              </li>
              <li class="breadcrumb-item" aria-current="page">
                Projects
              </li>
            </ol>
          </nav>
        </div>
      </div>
      {/* <!-- Page Header End --> */}

      {/* <!-- Fact Start --> */}
      <div class="container-fluid bg-secondary py-5">
        <div class="container">
          <div class="row">
            <div class="col-lg-3 wow fadeIn" data-wow-delay=".1s">
              <div class="d-flex counter">
                <h1 class="me-3 text-primary counter-value">99</h1>
                <h5 class="text-white mt-1">
                  Success in getting happy customer
                </h5>
              </div>
            </div>
            <div class="col-lg-3 wow fadeIn" data-wow-delay=".3s">
              <div class="d-flex counter">
                <h1 class="me-3 text-primary counter-value">25</h1>
                <h5 class="text-white mt-1">
                  Thousands of successful business
                </h5>
              </div>
            </div>
            <div class="col-lg-3 wow fadeIn" data-wow-delay=".5s">
              <div class="d-flex counter">
                <h1 class="me-3 text-primary counter-value">120</h1>
                <h5 class="text-white mt-1">Total clients who love HighTech</h5>
              </div>
            </div>
            <div class="col-lg-3 wow fadeIn" data-wow-delay=".7s">
              <div class="d-flex counter">
                <h1 class="me-3 text-primary counter-value">5</h1>
                <h5 class="text-white mt-1">
                  Stars reviews given by satisfied clients
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Fact End --> */}

      {/* <!-- Project Start --> */}
      {/* <div class="container-fluid project py-5 my-5">
        <div class="container py-5">
          <div
            class="text-center mx-auto pb-5 wow fadeIn"
            data-wow-delay=".3s"
            style={{ maxWidth: "600px" }}
          >
            <h5 class="text-primary">Our Project</h5>
            <h1>Our Recently Completed Projects</h1>
          </div>
          <div class="row g-5">
            <div class="col-md-6 col-lg-4 wow fadeIn" data-wow-delay=".3s">
              <div class="project-item">
                <div class="project-img">
                  <img
                    src="img/project-1.jpg"
                    class="img-fluid w-100 rounded"
                    alt=""
                  />
                  <div class="project-content">
                    <Link to="#" class="text-center">
                      <h4 class="text-secondary">Web design</h4>
                      <p class="m-0 text-white">Web Analysis</p>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-4 wow fadeIn" data-wow-delay=".5s">
              <div class="project-item">
                <div class="project-img">
                  <img
                    src="img/project-2.jpg"
                    class="img-fluid w-100 rounded"
                    alt=""
                  />
                  <div class="project-content">
                    <Link to="#" class="text-center">
                      <h4 class="text-secondary">Cyber Security</h4>
                      <p class="m-0 text-white">Cyber Security Core</p>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-4 wow fadeIn" data-wow-delay=".7s">
              <div class="project-item">
                <div class="project-img">
                  <img
                    src="img/project-3.jpg"
                    class="img-fluid w-100 rounded"
                    alt=""
                  />
                  <div class="project-content">
                    <Link to="#" class="text-center">
                      <h4 class="text-secondary">Mobile App</h4>
                      <p class="m-0 text-white">Android & ios Phone</p>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-4 wow fadeIn" data-wow-delay=".3s">
              <div class="project-item">
                <div class="project-img">
                  <img
                    src="img/project-4.jpg"
                    class="img-fluid w-100 rounded"
                    alt=""
                  />
                  <div class="project-content">
                    <Link to="#" class="text-center">
                      <h4 class="text-secondary">Web Development</h4>
                      <p class="m-0 text-white">Web Analysis</p>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-4 wow fadeIn" data-wow-delay=".5s">
              <div class="project-item">
                <div class="project-img">
                  <img
                    src="img/project-5.jpg"
                    class="img-fluid w-100 rounded"
                    alt=""
                  />
                  <div class="project-content">
                    <Link to="#" class="text-center">
                      <h4 class="text-secondary">Digital Marketing</h4>
                      <p class="m-0 text-white">Marketing Analysis</p>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-4 wow fadeIn" data-wow-delay=".7s">
              <div class="project-item">
                <div class="project-img">
                  <img
                    src="img/project-6.jpg"
                    class="img-fluid w-100 rounded"
                    alt=""
                  />
                  <div class="project-content">
                    <Link to="#" class="text-center">
                      <h4 class="text-secondary">Development Research</h4>
                      <p class="m-0 text-white">Development Analysis</p>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      <div class="container-fluid services py-5 mb-5">
        <div class="container">
          <div
            class="text-center mx-auto pb-5 wow fadeIn"
            data-wow-delay=".3s"
            style={{ maxWidth: "600px" }}
          >
            <h5 class="text-primary">Our Project</h5>
            <h1>Our Recently Completed And In Process Projects</h1>
          </div>
          <div class="row g-5 services-inner">
            <div class="col-md-6 col-lg-4 wow fadeIn" data-wow-delay=".3s">
              <div class="services-item bg-light">
                <div class="p-4 text-center services-content">
                  <div class="services-content-icon">
                    <img
                      src="/img/digitalstorelogo.png"
                      class="mb-4"
                      alt="Digital Pay Logo"
                      style={{
                        width: "200px",
                        height: "200px",
                        objectFit: "contain",
                      }}
                    ></img>
                    <h4 class="mb-3">Digital Store</h4>
                    <p class="mb-4">
                      Digital Store is India's largest electronics retailer,
                      having 600+ large format stores across India. The brand
                      offers 5,000+ products from 300+ international and
                      national brands. Here, customers can avail best deals on
                      the widest range of products like TVs, ACs, mobile phones,
                      laptops, and more. At each store, the trained staff
                      thoroughly understands the customer's requirement before
                      empowering them with a precise technology solution.
                    </p>
                    <Link
                      to="/project"
                      class="btn btn-secondary text-white px-5 py-3 rounded-pill"
                    >
                      Read More
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-4 wow fadeIn" data-wow-delay=".5s">
              <div class="services-item bg-light">
                <div class="p-4 text-center services-content">
                  <div class="services-content-icon">
                    <img
                      src="/img/digitalpayLogo-removebg-preview.png"
                      class="mb-4"
                      alt="Digital Pay Logo"
                      style={{
                        width: "200px",
                        height: "200px",
                        objectFit: "contain",
                      }}
                    ></img>
                    <h4 class="mb-3">Digital Pay Money</h4>
                    <p class="mb-4">
                      Digital Pay Money, enable local retail stores to offer
                      Assisted Digital Financial Services like Cash Deposit,
                      Cash Withdrawal, Balance Inquiry, Bill Payments, Micro
                      ATM, DTH-Mobile Recharges, Wallet Services POS Services,
                      SMS Payment, Insurance, Money Transfer etc.
                    </p>
                    <Link
                      to="/project"
                      class="btn btn-secondary text-white px-5 py-3 rounded-pill"
                    >
                      Read More
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-4 wow fadeIn" data-wow-delay=".7s">
              <div class="services-item bg-light">
                <div class="p-4 text-center services-content">
                  <div class="services-content-icon">
                    {/* <i class="fa fa-external-link-alt fa-7x mb-4 text-primary"></i> */}
                    <img
                      src="/img/png-transparent-e-commerce-logo-logo-e-commerce-electronic-business-ecommerce-angle-text-service-removebg-preview.png"
                      class="mb-4"
                      alt="Digital Pay Logo"
                      style={{
                        width: "200px",
                        height: "200px",
                        objectFit: "contain",
                      }}
                    ></img>
                    <h4 class="mb-3">Sanitary E-Commerce Website</h4>
                    <p class="mb-4">
                      Sanitary E-Commerce Website is India's largest Sanitary
                      retailer, having 250+ large format stores across India.
                      The brand offers 2,500+ products from 300+ international
                      and national brands. Here, customers can avail best deals
                      on the widest range of products like TVs, ACs, mobile
                      phones, laptops, and more. At each store, the trained
                      staff thoroughly understands the customer's requirement
                      before empowering them with a precise technology solution.
                    </p>
                    <Link
                      to="/project"
                      class="btn btn-secondary text-white px-5 py-3 rounded-pill"
                    >
                      Read More
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Project End -->/ */}

      {/* <!-- Back to Top --> */}
      <button
        className="btn btn-secondary btn-square rounded-circle back-to-top"
        onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
      >
        <i className="fa fa-arrow-up text-white"></i>
      </button>
    </>
  );
};

export default Project;
