import OwlCarousel from "react-owl-carousel";
import React, { useEffect } from "react";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXTwitter, faGithub } from "@fortawesome/free-brands-svg-icons";
import { Link } from "react-router-dom";

const Home = () => {
  return (
    <>
      {/* <!-- Spinner Start --> */}
      {/* <div id="spinner" class="show position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center">
            <div class="spinner-grow text-primary" role="status"></div>
        </div> */}
      {/* <!-- Spinner End --> */}

      {/* <!-- Carousel Start --> */}
      <div class="container-fluid px-0">
        <div id="carouselId" class="carousel slide" data-bs-ride="carousel">
          <ol class="carousel-indicators">
            <li
              data-bs-target="#carouselId"
              data-bs-slide-to="0"
              class="active"
              aria-current="true"
              aria-label="First slide"
            ></li>
            <li
              data-bs-target="#carouselId"
              data-bs-slide-to="1"
              aria-label="Second slide"
            ></li>
          </ol>
          <div class="carousel-inner" role="listbox">
            <div class="carousel-item active">
              <img
                src="img/carousel-1.jpg"
                class="img-fluid"
                alt="First slide"
              />
              <div class="carousel-caption">
                <div class="container carousel-content">
                  <h6 class="text-secondary h4 animated fadeInUp">
                    Best IT Solutions
                  </h6>
                  <h1 class="text-white display-1 mb-4 animated fadeInRight">
                    An Innovative IT Solutions Agency
                  </h1>
                  <p class="mb-4 text-white fs-5 animated fadeInDown">
                    DigitalAdd is a leading fintech company that revolutionizes
                    the digital payment landscape, providing a comprehensive
                    range of services to individuals and businesses alike.
                  </p>
                  {/* <Link to='#' class="me-2">
                    <button
                      type="button"
                      class="px-4 py-sm-3 px-sm-5 btn btn-primary rounded-pill carousel-content-btn1 animated fadeInLeft"
                    >
                      Read More
                    </button>
                  </Link> */}
                  <Link to="/contact" class="ms-2">
                    <button
                      type="button"
                      class="px-4 py-sm-3 px-sm-5 btn btn-primary rounded-pill carousel-content-btn2 animated fadeInRight"
                    >
                      Contact Us
                    </button>
                  </Link>
                </div>
              </div>
            </div>
            <div class="carousel-item">
              <img
                src="img/carousel-2.jpg"
                class="img-fluid"
                alt="Second slide"
              />
              <div class="carousel-caption">
                <div class="container carousel-content">
                  <h6 class="text-secondary h4 animated fadeInUp">
                    Best IT Solutions
                  </h6>
                  <h1 class="text-white display-1 mb-4 animated fadeInLeft">
                    Quality Digital Services You Really Need!
                  </h1>
                  <p class="mb-4 text-white fs-5 animated fadeInDown">
                    It can be challenging to choose the best software
                    development business that can help your internet product
                    succeed and fulfill your dreams
                  </p>
                  {/* <Link to="/" class="me-2">
                    <button
                      type="button"
                      class="px-4 py-sm-3 px-sm-5 btn btn-primary rounded-pill carousel-content-btn1 animated fadeInLeft"
                    >
                      Read More
                    </button>
                  </Link> */}
                  <Link to="/contact" class="ms-2">
                    <button
                      type="button"
                      class="px-4 py-sm-3 px-sm-5 btn btn-primary rounded-pill carousel-content-btn2 animated fadeInRight"
                    >
                      Contact Us
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <button
            class="carousel-control-prev"
            type="button"
            data-bs-target="#carouselId"
            data-bs-slide="prev"
          >
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
          </button>
          <button
            class="carousel-control-next"
            type="button"
            data-bs-target="#carouselId"
            data-bs-slide="next"
          >
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
          </button>
        </div>
      </div>
      {/* <!-- Carousel End --> */}

      {/* <!-- Fact Start --> */}
      <div class="container-fluid bg-secondary py-5">
        <div class="container">
          <div class="row">
            <div class="col-lg-3 wow fadeIn" data-wow-delay=".1s">
              <div class="d-flex counter">
                <h1 class="me-3 text-primary counter-value">99</h1>
                <h5 class="text-white mt-1">
                  Success in getting happy customer
                </h5>
              </div>
            </div>
            <div class="col-lg-3 wow fadeIn" data-wow-delay=".3s">
              <div class="d-flex counter">
                <h1 class="me-3 text-primary counter-value">25</h1>
                <h5 class="text-white mt-1">
                  Thousands of successful business
                </h5>
              </div>
            </div>
            <div class="col-lg-3 wow fadeIn" data-wow-delay=".5s">
              <div class="d-flex counter">
                <h1 class="me-3 text-primary counter-value">120</h1>
                <h5 class="text-white mt-1">Total clients who love HighTech</h5>
              </div>
            </div>
            <div class="col-lg-3 wow fadeIn" data-wow-delay=".7s">
              <div class="d-flex counter">
                <h1 class="me-3 text-primary counter-value">5</h1>
                <h5 class="text-white mt-1">
                  Stars reviews given by satisfied clients
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Fact End --> */}

      {/* <!-- About Start --> */}
      <div class="container-fluid py-5 my-5">
        <div class="container pt-5">
          <div class="row g-5">
            <div
              class="col-lg-5 col-md-6 col-sm-12 wow fadeIn"
              data-wow-delay=".3s"
            >
              <div class="h-100 position-relative">
                <img
                  src="img/about-1.jpg"
                  class="img-fluid w-75 rounded"
                  alt=""
                  style={{ marginBottom: "25%" }}
                />
                <div
                  class="position-absolute w-75"
                  style={{ top: "25%", left: "25%" }}
                >
                  <img
                    src="img/about-2.jpg"
                    class="img-fluid w-100 rounded"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div
              class="col-lg-7 col-md-6 col-sm-12 wow fadeIn"
              data-wow-delay=".5s"
            >
              <h5 class="text-primary">About Us</h5>
              <h1 class="mb-4">
                About HighTech Agency And It's Innovative IT Solutions
              </h1>
              <p>
                DigitalAdd is a leading fintech company that revolutionizes the
                digital payment landscape, providing a comprehensive range of
                services to individuals and businesses alike.
              </p>
              <p class="mb-4">
                It can be challenging to choose the best software development
                business that can help your internet product succeed and fulfill
                your dreams. Here, we at DigitalAdd World, ensure that you chose
                the right course of action for your project from the outset and
                that the final outcome is exactly what you had in mind. For our
                experts, clean code implementation is not challenging but quite
                interesting.
              </p>
              <Link
                to="/about"
                class="btn btn-secondary rounded-pill px-5 py-3 text-white"
              >
                More Details
              </Link>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- About End --> */}

      {/* <!-- Services Start --> */}
      <div class="container-fluid services py-5 mb-5">
        <div class="container">
          <div
            class="text-center mx-auto pb-5 wow fadeIn"
            data-wow-delay=".3s"
            style={{ maxWidth: "600px" }}
          >
            <h5 class="text-primary">Our Services</h5>
            <h1>Services Built Specifically For Your Business</h1>
          </div>
          <div class="row g-5 services-inner">
            <div class="col-md-6 col-lg-4 wow fadeIn" data-wow-delay=".3s">
              <div class="services-item bg-light">
                <div class="p-4 text-center services-content">
                  <div class="services-content-icon">
                    <i class="fa fa-code fa-7x mb-4 text-primary"></i>
                    <h4 class="mb-3">Web Design</h4>

                    <p class="mb-4">
                      We create visually stunning, user-friendly web designs
                      that capture your brand's essence. Our designs focus on
                      aesthetics, functionality, and delivering an engaging
                      experience for your audience.
                    </p>

                    <Link
                      to="/service"
                      class="btn btn-secondary text-white px-5 py-3 rounded-pill"
                    >
                      Read More
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-4 wow fadeIn" data-wow-delay=".5s">
              <div class="services-item bg-light">
                <div class="p-4 text-center services-content">
                  <div class="services-content-icon">
                    <i class="fa fa-file-code fa-7x mb-4 text-primary"></i>
                    <h4 class="mb-3">Web Development</h4>
                    <p class="mb-4">
                      We build responsive, high-performance websites that
                      deliver seamless user experiences across all devices. Our
                      custom web development solutions are tailored to meet your
                      business goals and drive online success.
                    </p>

                    <Link
                      to="/service"
                      class="btn btn-secondary text-white px-5 py-3 rounded-pill"
                    >
                      Read More
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-4 wow fadeIn" data-wow-delay=".7s">
              <div class="services-item bg-light">
                <div class="p-4 text-center services-content">
                  <div class="services-content-icon">
                    <i class="fa fa-external-link-alt fa-7x mb-4 text-primary"></i>
                    <h4 class="mb-3">App Development</h4>
                    <p class="mb-4">
                      We create high-performance, feature-rich mobile apps
                      tailored to your business needs, ensuring seamless
                      functionality across all platforms. From concept to
                      deployment, our team delivers innovative solutions that
                      engage and empower users.
                    </p>

                    <Link
                      to="/service"
                      class="btn btn-secondary text-white px-5 py-3 rounded-pill"
                    >
                      Read More
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-4 wow fadeIn" data-wow-delay=".3s">
              <div class="services-item bg-light">
                <div class="p-4 text-center services-content">
                  <div class="services-content-icon">
                    <i class="fas fa-user-secret fa-7x mb-4 text-primary"></i>
                    <h4 class="mb-3">Web Security</h4>
                    <p class="mb-4">
                      We provide comprehensive web security solutions that
                      protect your website from cyber threats, ensuring data
                      integrity and confidentiality. Our services include
                      encryption, vulnerability assessments, and real-time
                      monitoring to safeguard your digital assets.
                    </p>

                    <Link
                      to="/service"
                      class="btn btn-secondary text-white px-5 py-3 rounded-pill"
                    >
                      Read More
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-4 wow fadeIn" data-wow-delay=".5s">
              <div class="services-item bg-light">
                <div class="p-4 text-center services-content">
                  <div class="services-content-icon">
                    <i class="fa fa-envelope-open fa-7x mb-4 text-primary"></i>
                    <h4 class="mb-3">Digital Marketing</h4>
                    <p class="mb-4">
                      Our digital marketing services help you reach your target
                      audience and grow your online presence through SEO, social
                      media, and paid advertising strategies. We create
                      data-driven campaigns designed to boost engagement and
                      drive conversions.
                    </p>
                    <Link
                      to="/service"
                      class="btn btn-secondary text-white px-5 py-3 rounded-pill"
                    >
                      Read More
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-4 wow fadeIn" data-wow-delay=".7s">
              <div class="services-item bg-light">
                <div class="p-4 text-center services-content">
                  <div class="services-content-icon">
                    <i class="fas fa-laptop fa-7x mb-4 text-primary"></i>
                    <h4 class="mb-3">Programming</h4>
                    <p class="mb-4">
                      We offer expert programming services, developing efficient
                      and scalable code tailored to your project needs. Our team
                      is proficient in multiple languages and frameworks,
                      ensuring robust solutions for web, mobile, and software
                      development.
                    </p>

                    <Link
                      to="/service"
                      class="btn btn-secondary text-white px-5 py-3 rounded-pill"
                    >
                      Read More
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Services End --> */}

      {/* <!-- Project Start --> */}
      {/* <div class="container-fluid project py-5 mb-5">
        <div class="container">
          <div
            class="text-center mx-auto pb-5 wow fadeIn"
            data-wow-delay=".3s"
            style={{ maxWidth: "600px" }}
          >
            <h5 class="text-primary">Our Project</h5>
            <h1>Our Recently Completed Projects</h1>
          </div>
          <div class="row g-5">
            <div class="col-md-6 col-lg-4 wow fadeIn" data-wow-delay=".3s">
              <div class="project-item">
                <div class="project-img">
                  <img
                    src="img/project-1.jpg"
                    class="img-fluid w-100 rounded"
                    alt=""
                  />
                  <div class="project-content">
                    <Link to="/project" class="text-center">
                      <h4 class="text-secondary">Digital Store</h4>
                      <p class="m-0 text-white">Web Analysis</p>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-4 wow fadeIn" data-wow-delay=".5s">
              <div class="project-item">
                <div class="project-img">
                  <img
                    src="img/project-2.jpg"
                    class="img-fluid w-100 rounded"
                    alt=""
                  />
                  <div class="project-content">
                    <Link to="/project" class="text-center">
                      <h4 class="text-secondary">Cyber Security</h4>
                      <p class="m-0 text-white">Cyber Security Core</p>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-4 wow fadeIn" data-wow-delay=".7s">
              <div class="project-item">
                <div class="project-img">
                  <img
                    src="img/project-3.jpg"
                    class="img-fluid w-100 rounded"
                    alt=""
                  />
                  <div class="project-content">
                    <Link to="/project" class="text-center">
                      <h4 class="text-secondary">Mobile App</h4>
                      <p class="m-0 text-white">Andoird & Ios Mobile </p>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-4 wow fadeIn" data-wow-delay=".3s">
              <div class="project-item">
                <div class="project-img">
                  <img
                    src="img/project-4.jpg"
                    class="img-fluid w-100 rounded"
                    alt=""
                  />
                  <div class="project-content">
                    <Link to="/project" class="text-center">
                      <h4 class="text-secondary">Web Development</h4>
                      <p class="m-0 text-white">Web Analysis</p>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-4 wow fadeIn" data-wow-delay=".5s">
              <div class="project-item">
                <div class="project-img">
                  <img
                    src="img/project-5.jpg"
                    class="img-fluid w-100 rounded"
                    alt=""
                  />
                  <div class="project-content">
                    <Link to="/project" class="text-center">
                      <h4 class="text-secondary">Digital Marketing</h4>
                      <p class="m-0 text-white">Marketing Analysis</p>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-4 wow fadeIn" data-wow-delay=".7s">
              <div class="project-item">
                <div class="project-img">
                  <img
                    src="img/project-6.jpg"
                    class="img-fluid w-100 rounded"
                    alt=""
                  />
                  <div class="project-content">
                    <Link to="/project" class="text-center">
                      <h4 class="text-secondary">Development Research</h4>
                      <p class="m-0 text-white">Development Analysis</p>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      <div class="container-fluid services py-5 mb-5">
        <div class="container">
          <div
            class="text-center mx-auto pb-5 wow fadeIn"
            data-wow-delay=".3s"
            style={{ maxWidth: "600px" }}
          >
            <h5 class="text-primary">Our Project</h5>
            <h1>Our Recently Completed And In Process Projects</h1>
          </div>
          <div class="row g-5 services-inner">
            <div class="col-md-6 col-lg-4 wow fadeIn" data-wow-delay=".3s">
              <div class="services-item bg-light">
                <div class="p-4 text-center services-content">
                  <div class="services-content-icon">
                    <img
                      src="/img/digitalstorelogo.png"
                      class="mb-4"
                      alt="Digital Pay Logo"
                      style={{
                        width: "200px",
                        height: "200px",
                        objectFit: "contain",
                      }}
                    ></img>
                    <h4 class="mb-3">Digital Store</h4>
                    <p class="mb-4">
                      Digital Store is India's largest electronics retailer,
                      having 600+ large format stores across India. The brand
                      offers 5,000+ products from 300+ international and
                      national brands. Here, customers can avail best deals on
                      the widest range of products like TVs, ACs, mobile phones,
                      laptops, and more. At each store, the trained staff
                      thoroughly understands the customer's requirement before
                      empowering them with a precise technology solution.
                    </p>

                    <Link
                      to="/project"
                      class="btn btn-secondary text-white px-5 py-3 rounded-pill"
                    >
                      Read More
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-4 wow fadeIn" data-wow-delay=".5s">
              <div class="services-item bg-light">
                <div class="p-4 text-center services-content">
                  <div class="services-content-icon">
                    <img
                      src="/img/digitalpayLogo-removebg-preview.png"
                      class="mb-4"
                      alt="Digital Pay Logo"
                      style={{
                        width: "200px",
                        height: "200px",
                        objectFit: "contain",
                      }}
                    ></img>
                    <h4 class="mb-3">Digital Pay Money</h4>
                    <p class="mb-4">
                      Digital Pay Money, enable local retail stores to offer
                      Assisted Digital Financial Services like Cash Deposit,
                      Cash Withdrawal, Balance Inquiry, Bill Payments, Micro
                      ATM, DTH-Mobile Recharges, Wallet Services POS Services,
                      SMS Payment, Insurance, Money Transfer etc.
                    </p>

                    <Link
                      to="/project"
                      class="btn btn-secondary text-white px-5 py-3 rounded-pill"
                    >
                      Read More
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-4 wow fadeIn" data-wow-delay=".7s">
              <div class="services-item bg-light">
                <div class="p-4 text-center services-content">
                  <div class="services-content-icon">
                    {/* <i class="fa fa-external-link-alt fa-7x mb-4 text-primary"></i> */}
                    <img
                      src="/img/png-transparent-e-commerce-logo-logo-e-commerce-electronic-business-ecommerce-angle-text-service-removebg-preview.png"
                      class="mb-4"
                      alt="Digital Pay Logo"
                      style={{
                        width: "200px",
                        height: "200px",
                        objectFit: "contain",
                      }}
                    ></img>
                    <h4 class="mb-3">Sanitary E-Commerce Website</h4>
                    <p class="mb-4">
                      Sanitary E-Commerce Website is India's largest Sanitary
                      retailer, having 250+ large format stores across India.
                      The brand offers 2,500+ products from 300+ international
                      and national brands. Here, customers can avail best deals
                      on the widest range of products like TVs, ACs, mobile
                      phones, laptops, and more. At each store, the trained
                      staff thoroughly understands the customer's requirement
                      before empowering them with a precise technology solution.
                    </p>

                    <Link
                      to="/project"
                      class="btn btn-secondary text-white px-5 py-3 rounded-pill"
                    >
                      Read More
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- Project End --> */}

      {/* <!-- Blog Start --> */}
      {/* <div class="container-fluid blog py-5 mb-5">
        <div class="container">
          <div
            class="text-center mx-auto pb-5 wow fadeIn"
            data-wow-delay=".3s"
            style={{ maxWidth: "600px" }}
          >
            <h5 class="text-primary">Our Blog</h5>
            <h1>Latest Blog & News</h1>
          </div>
          <div class="row g-5 justify-content-center">
            <div class="col-lg-6 col-xl-4 wow fadeIn" data-wow-delay=".3s">
              <div class="blog-item position-relative bg-light rounded">
                <img
                  src="img/blog-1.jpg"
                  class="img-fluid w-100 rounded-top"
                  alt=""
                />
                <span
                  class="position-absolute px-4 py-3 bg-primary text-white rounded"
                  style={{ top: "-28px", right: "20px" }}
                >
                  Web Design
                </span>
                <div
                  class="blog-btn d-flex justify-content-between position-relative px-3"
                  style={{ marginTop: "-75px" }}
                >
                  <div class="blog-icon btn btn-secondary px-3 rounded-pill my-auto">
                    <Link to="/blog" class="btn text-white">
                      Read More
                    </Link>
                  </div>
                  <div class="blog-btn-icon btn btn-secondary px-4 py-3 rounded-pill ">
                    <div class="blog-icon-1">
                      <p class="text-white px-2">
                        Share<i class="fa fa-arrow-right ms-3"></i>
                      </p>
                    </div>
                    <div class="blog-icon-2">
                      <Link to="" class="btn me-1">
                        <i class="fab fa-facebook-f text-white"></i>
                      </Link>
                      <Link to="" class="btn me-1">
                        <i class="fab fa-twitter text-white"></i>
                      </Link>
                      <Link to="" class="btn me-1">
                        <i class="fab fa-instagram text-white"></i>
                      </Link>
                    </div>
                  </div>
                </div>
                <div
                  class="blog-content text-center position-relative px-3"
                  style={{ marginTop: "-25px" }}
                >
                  <img
                    src="img/admin.jpg"
                    class="img-fluid rounded-circle border border-4 border-white mb-3"
                    alt=""
                  />
                  <h5 class="">By Daniel Martin</h5>
                  <span class="text-secondary">24 March 2023</span>
                  <p class="py-2">
                    "Thank you for the fantastic web design! The layout is
                    clean, and everything functions perfectly."
                  </p>
                </div>
                <div class="blog-coment d-flex justify-content-between px-4 py-2 border bg-primary rounded-bottom">
                  <Link to="" class="text-white">
                    <small>
                      <i class="fas fa-share me-2 text-secondary"></i>233 Share
                    </small>
                  </Link>
                  <Link to="" class="text-white">
                    <small>
                      <i class="fa fa-comments me-2 text-secondary"></i>5
                      Comments
                    </small>
                  </Link>
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-xl-4 wow fadeIn" data-wow-delay=".5s">
              <div class="blog-item position-relative bg-light rounded">
                <img
                  src="img/blog-2.jpg"
                  class="img-fluid w-100 rounded-top"
                  alt=""
                />
                <span
                  class="position-absolute px-4 py-3 bg-primary text-white rounded"
                  style={{ top: "-28px", right: "20px" }}
                >
                  Development
                </span>
                <div
                  class="blog-btn d-flex justify-content-between position-relative px-3"
                  style={{ marginTop: "-75px" }}
                >
                  <div class="blog-icon btn btn-secondary px-3 rounded-pill my-auto">
                    <Link to="/blog" class="btn text-white ">
                      Read More
                    </Link>
                  </div>
                  <div class="blog-btn-icon btn btn-secondary px-4 py-3 rounded-pill ">
                    <div class="blog-icon-1">
                      <p class="text-white px-2">
                        Share<i class="fa fa-arrow-right ms-3"></i>
                      </p>
                    </div>
                    <div class="blog-icon-2">
                      <Link to="" class="btn me-1">
                        <i class="fab fa-facebook-f text-white"></i>
                      </Link>
                      <Link to="" class="btn me-1">
                        <i class="fab fa-twitter text-white"></i>
                      </Link>
                      <Link to="" class="btn me-1">
                        <i class="fab fa-instagram text-white"></i>
                      </Link>
                    </div>
                  </div>
                </div>
                <div
                  class="blog-content text-center position-relative px-3"
                  style={{ marginTop: "-25px" }}
                >
                  <img
                    src="img/admin.jpg"
                    class="img-fluid rounded-circle border border-4 border-white mb-3"
                    alt=""
                  />
                  <h5 class="">By Daniel Martin</h5>
                  <span class="text-secondary">29 April 2024</span>
                  <p class="py-2">
                    "We're impressed with the development work; the website is
                    user-friendly and looks professional."
                  </p>
                </div>
                <div class="blog-coment d-flex justify-content-between px-4 py-2 border bg-primary rounded-bottom">
                  <Link to="" class="text-white">
                    <small>
                      <i class="fas fa-share me-2 text-secondary"></i>134 Share
                    </small>
                  </Link>
                  <Link to="" class="text-white">
                    <small>
                      <i class="fa fa-comments me-2 text-secondary"></i>8
                      Comments
                    </small>
                  </Link>
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-xl-4 wow fadeIn" data-wow-delay=".7s">
              <div class="blog-item position-relative bg-light rounded">
                <img
                  src="img/blog-3.jpg"
                  class="img-fluid w-100 rounded-top"
                  alt=""
                />
                <span
                  class="position-absolute px-4 py-3 bg-primary text-white rounded"
                  style={{ top: "-28px", right: "20px" }}
                >
                  Mobile App
                </span>
                <div
                  class="blog-btn d-flex justify-content-between position-relative px-3"
                  style={{ marginTop: "-75px" }}
                >
                  <div class="blog-icon btn btn-secondary px-3 rounded-pill my-auto">
                    <Link to="/blog" class="btn text-white ">
                      Read More
                    </Link>
                  </div>
                  <div class="blog-btn-icon btn btn-secondary px-4 py-3 rounded-pill ">
                    <div class="blog-icon-1">
                      <p class="text-white px-2">
                        Share<i class="fa fa-arrow-right ms-3"></i>
                      </p>
                    </div>
                    <div class="blog-icon-2">
                      <Link to="" class="btn me-1">
                        <i class="fab fa-facebook-f text-white"></i>
                      </Link>
                      <Link to="" class="btn me-1">
                        <i class="fab fa-twitter text-white"></i>
                      </Link>
                      <Link to="" class="btn me-1">
                        <i class="fab fa-instagram text-white"></i>
                      </Link>
                    </div>
                  </div>
                </div>
                <div
                  class="blog-content text-center position-relative px-3"
                  style={{ marginTop: "-25px" }}
                >
                  <img
                    src="img/admin.jpg"
                    class="img-fluid rounded-circle border border-4 border-white mb-3"
                    alt=""
                  />
                  <h5 class="">By Daniel Martin</h5>
                  <span class="text-secondary">14 sept 2024</span>
                  <p class="py-2">
                    "Absolutely love the new design; it's modern and
                    user-friendly!"
                  </p>
                </div>
                <div class="blog-coments d-flex justify-content-between px-4 py-2 border bg-primary rounded-bottom">
                  <Link to="" class="text-white">
                    <small>
                      <i class="fas fa-share me-2 text-secondary"></i>102 Share
                    </small>
                  </Link>
                  <Link to="" class="text-white">
                    <small>
                      <i class="fa fa-comments me-2 text-secondary"></i>3
                      Comments
                    </small>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      {/* <!-- Blog End --> */}

      {/* <!-- Team Start --> */}
      <div className="container-fluid py-5 mb-5 team">
        <div className="container">
          <div
            className="text-center mx-auto pb-5 wow fadeIn"
            data-wow-delay=".3s"
            style={{ maxWidth: "600px" }}
          >
            <h5 className="text-primary">Our Team</h5>
            <h1>Meet our expert Team</h1>
          </div>

          {/* OwlCarousel for team members */}
          <OwlCarousel
            className="owl-carousel team-carousel wow fadeIn"
            loop
            margin={10}
            nav
            autoplay={true}
            autoplayTimeout={3000}
            smartSpeed={1000}
            responsive={{
              0: {
                items: 1, // 1 item on mobile screens
              },
              600: {
                items: 2, // 2 items on tablet
              },
              1000: {
                items: 3, // 3 items on desktop
              },
            }}
          >
            {/* Team Member 1 */}
            <div className="rounded team-item">
              <div className="team-content">
                <div className="team-img-icon">
                  <div className="team-img rounded-circle">
                    <img
                      src="img/depikaMamPic1.jpg"
                      className="img-fluid w-100 rounded-circle teampic"
                      alt=""
                    />
                  </div>
                  <div className="team-name text-center py-3">
                    <h4>Depika Lakhera </h4>
                    <p className="m-0">HR Administrator</p>
                  </div>
                  <div className="team-icon d-flex justify-content-center pb-4">
                    <Link
                      className="btn btn-square btn-secondary text-white rounded-circle m-1"
                      to=""
                    >
                      <FontAwesomeIcon icon={faXTwitter} />
                    </Link>
                    <Link
                      className="btn btn-square btn-secondary text-white rounded-circle m-1"
                      to=""
                    >
                      <FontAwesomeIcon icon={faGithub} />
                    </Link>
                    <Link
                      className="btn btn-square btn-secondary text-white rounded-circle m-1"
                      to=""
                    >
                      <i className="fab fa-linkedin-in"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            {/* Team Member 2 */}
            <div className="rounded team-item">
              <div className="team-content">
                <div className="team-img-icon">
                  <div className="team-img rounded-circle">
                    <img
                      src="img/manishPic.jpg"
                      className="img-fluid w-100 rounded-circle teampic"
                      alt=""
                    />
                  </div>
                  <div className="team-name text-center py-3">
                    <h4>Manish Sharma</h4>
                    <p className="m-0">MERN STACK DEVELOPER</p>
                  </div>
                  <div className="team-icon d-flex justify-content-center pb-4">
                    <Link
                      className="btn btn-square btn-secondary text-white rounded-circle m-1"
                      to=""
                    >
                      <FontAwesomeIcon icon={faXTwitter} />
                    </Link>
                    <Link
                      className="btn btn-square btn-secondary text-white rounded-circle m-1"
                      to=""
                    >
                      <FontAwesomeIcon icon={faGithub} />
                    </Link>
                    <Link
                      className="btn btn-square btn-secondary text-white rounded-circle m-1"
                      to=""
                    >
                      <i className="fab fa-linkedin-in"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            {/* Team Member 3 */}

            <div className="rounded team-item">
              <div className="team-content">
                <div className="team-img-icon">
                  <div className="team-img rounded-circle">
                    <img
                      src="img/bhupendraPic.jpg"
                      className="img-fluid w-100 rounded-circle"
                      alt=""
                    />
                  </div>
                  <div className="team-name text-center py-3">
                    <h4>Bhupendra Singh</h4>
                    <p className="m-0">PYTHON DEVELOPER</p>
                  </div>
                  <div className="team-icon d-flex justify-content-center pb-4">
                    <Link
                      className="btn btn-square btn-secondary text-white rounded-circle m-1"
                      to=""
                    >
                      <FontAwesomeIcon icon={faXTwitter} />
                    </Link>
                    <Link
                      className="btn btn-square btn-secondary text-white rounded-circle m-1"
                      to=""
                    >
                      <FontAwesomeIcon icon={faGithub} />
                    </Link>
                    <Link
                      className="btn btn-square btn-secondary text-white rounded-circle m-1"
                      to=""
                    >
                      <i className="fab fa-linkedin-in"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            {/* Team Member 4 */}
            <div className="rounded team-item">
              <div className="team-content">
                <div className="team-img-icon">
                  <div className="team-img rounded-circle">
                    <img
                      src="img/resize-1729165235116830296tanishaJainPic.jpg"
                      className="img-fluid w-100 rounded-circle"
                      alt=""
                    />
                  </div>
                  <div className="team-name text-center py-3">
                    <h4>TANISHA JAIN</h4>
                    <p className="m-0">DIGITAL MARKETING</p>
                  </div>
                  <div className="team-icon d-flex justify-content-center pb-4">
                    <Link
                      className="btn btn-square btn-secondary text-white rounded-circle m-1"
                      to=""
                    >
                      <FontAwesomeIcon icon={faXTwitter} />
                    </Link>
                    <Link
                      className="btn btn-square btn-secondary text-white rounded-circle m-1"
                      to=""
                    >
                      <FontAwesomeIcon icon={faGithub} />
                    </Link>
                    <Link
                      className="btn btn-square btn-secondary text-white rounded-circle m-1"
                      to=""
                    >
                      <i className="fab fa-linkedin-in"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            {/* Team Member 5 */}
            <div className="rounded team-item">
              <div className="team-content">
                <div className="team-img-icon">
                  <div className="team-img rounded-circle">
                    <img
                      src="img/team-1.jpg"
                      className="img-fluid w-100 rounded-circle"
                      alt=""
                    />
                  </div>
                  <div className="team-name text-center py-3">
                    <h4>SHALINI SHARMA </h4>
                    <p className="m-0">FULL STACK TRANEE</p>
                  </div>
                  <div className="team-icon d-flex justify-content-center pb-4">
                    <Link
                      className="btn btn-square btn-secondary text-white rounded-circle m-1"
                      to=""
                    >
                      <FontAwesomeIcon icon={faXTwitter} />
                    </Link>
                    <Link
                      className="btn btn-square btn-secondary text-white rounded-circle m-1"
                      to=""
                    >
                      <FontAwesomeIcon icon={faGithub} />
                    </Link>
                    <Link
                      className="btn btn-square btn-secondary text-white rounded-circle m-1"
                      to=""
                    >
                      <i className="fab fa-linkedin-in"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </OwlCarousel>
        </div>
      </div>

      {/* <!-- Team End --> */}

      {/* <!-- Testimonial Start --> */}
      {/* <div class="container-fluid testimonial py-5 mb-5">
            <div class="container">
                <div class="text-center mx-auto pb-5 wow fadeIn" data-wow-delay=".3s" style={{ "maxWidth": "600px" }}>
                    <h5 class="text-primary">Our Testimonial</h5>
                    <h1>Our Client Saying!</h1>
                </div>
                <div class="owl-carousel testimonial-carousel wow fadeIn" data-wow-delay=".5s">
                    <div class="testimonial-item border p-4">
                        <div class="d-flex align-items-center">
                            <div class="">
                                <img src="img/testimonial-1.jpg" alt="" />
                            </div>
                            <div class="ms-4">
                                <h4 class="text-secondary">Nikita</h4>
                                <p class="m-0 pb-3">Geoffrey Taber Chair in Entrepreneurship and Innovation, University of Toronto, Rotman School of Management</p>
                                <div class="d-flex pe-5">
                                    <i class="fas fa-star me-1 text-primary"></i>
                                    <i class="fas fa-star me-1 text-primary"></i>
                                    <i class="fas fa-star me-1 text-primary"></i>
                                    <i class="fas fa-star me-1 text-primary"></i>
                                    <i class="fas fa-star me-1 text-primary"></i>
                                </div>
                            </div>
                        </div>
                        <div class="border-top mt-4 pt-3">
                            <p class="mb-0">“I am really impressed by the quality of services I received from digitaladd world Technology. You were right on schedule, charged reasonable prices, were professional and courteous in dealings, and delivered items well before time. I have got
                                a good e-commerce site for my products. My revenue has increased because of digitaladdworld and I will definitely use your services again.”</p>
                        </div>
                    </div>
                    <div class="testimonial-item border p-4">
                        <div class=" d-flex align-items-center">
                            <div class="">
                                <img src="img/testimonial-2.jpg" alt="" />
                            </div>
                            <div class="ms-4">
                                <h4 class="text-secondary">Amit Agrawal</h4>
                                <p class="m-0 pb-3">Former Chief Digital, Data, and Analytics Officer at Marsh LLC</p>
                                <div class="d-flex pe-5">
                                    <i class="fas fa-star me-1 text-primary"></i>
                                    <i class="fas fa-star me-1 text-primary"></i>
                                    <i class="fas fa-star me-1 text-primary"></i>
                                    <i class="fas fa-star me-1 text-primary"></i>
                                    <i class="fas fa-star me-1 text-primary"></i>
                                </div>
                            </div>
                        </div>
                        <div class="border-top mt-4 pt-3">
                            <p class="mb-0">
                                “We are very happy with digitaladd world Technology; very serious and consistent in their work. Sandeep and his
                                team of programmers have always been there for us all the time. This is a company I can recommend to anyone to perform any work.”
                            </p>
                        </div>
                    </div>
                    <div class="testimonial-item border p-4">
                        <div class=" d-flex align-items-center">
                            <div class="">
                                <img src="img/testimonial-3.jpg" alt="" />
                            </div>
                            <div class="ms-4">
                                <h4 class="text-secondary">Khushi Sharma</h4>
                                <p class="m-0 pb-3">
                                    Former Partner, Goldman Sachs</p>
                                <div class="d-flex pe-5">
                                    <i class="fas fa-star me-1 text-primary"></i>
                                    <i class="fas fa-star me-1 text-primary"></i>
                                    <i class="fas fa-star me-1 text-primary"></i>
                                    <i class="fas fa-star me-1 text-primary"></i>
                                    <i class="fas fa-star me-1 text-primary"></i>
                                </div>
                            </div>
                        </div>
                        <div class="border-top mt-4 pt-3">
                            <p class="mb-0">
                                “We have worked with digitaladd world Technology on various projects, and find that they provide quality service and expertise for our
                                programming needs. It is rare to find a service provider with such professional consistency - they are a valued service provider to our business!”
                            </p>
                        </div>
                    </div>
                    <div class="testimonial-item border p-4">
                        <div class=" d-flex align-items-center">
                            <div class="">
                                <img src="img/testimonial-4.jpg" alt="" />
                            </div>
                            <div class="ms-4">
                                <h4 class="text-secondary">Jim Madden </h4>
                                <p class="m-0 pb-3">Chief Financial Officer, Rockwell Automation</p>
                                <div class="d-flex pe-5">
                                    <i class="fas fa-star me-1 text-primary"></i>
                                    <i class="fas fa-star me-1 text-primary"></i>
                                    <i class="fas fa-star me-1 text-primary"></i>
                                    <i class="fas fa-star me-1 text-primary"></i>
                                    <i class="fas fa-star me-1 text-primary"></i>
                                </div>
                            </div>
                        </div>
                        <div class="border-top mt-4 pt-3">
                            <p class="mb-0">
                                "Outstanding service! The team was incredibly responsive and always
                                available for support. They truly understand what their clients need and deliver with precision."
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div> */}

      {/* <!-- Testimonial End --> */}

      {/* <!-- Contact Start --> */}
      <div class="container-fluid py-5 mb-5">
        <div class="container">
          <div
            class="text-center mx-auto pb-5 wow fadeIn"
            data-wow-delay=".3s"
            style={{ maxWidth: "600px" }}
          >
            <h5 class="text-primary">Get In Touch</h5>
            <h1 class="mb-3">Contact for any query</h1>
            <p class="mb-2">
              The contact form is currently inactive. Get a functional and
              working contact form with Ajax & Javascript in a few minutes. Just
              copy and paste the files, add a little code and you're done.{" "}
              <Link to="https://htmlcodex.com/contact-form">Download Now</Link>.
            </p>
          </div>
          <div class="contact-detail position-relative p-5">
            <div class="row g-5 mb-5 justify-content-center">
              <div class="col-xl-4 col-lg-6 wow fadeIn" data-wow-delay=".3s">
                <div class="d-flex bg-light p-3 rounded">
                  <div
                    class="flex-shrink-0 btn-square bg-secondary rounded-circle"
                    style={{ width: "50px", height: "50px" }}
                  >
                    <i class="fas fa-map-marker-alt text-white"></i>
                  </div>
                  <div class="ms-3">
                    <h4 class="text-primary">Address</h4>
                    <Link
                      to="https://www.google.com/maps/search/?api=1&query=26.850811,75.790472"
                      target="_blank"
                      class="h5"
                    >
                      Jaipur-302020 , Rajasthan{" "}
                    </Link>
                  </div>
                </div>
              </div>
              <div class="col-xl-4 col-lg-6 wow fadeIn" data-wow-delay=".5s">
                <div class="d-flex bg-light p-3 rounded">
                  <div
                    class="flex-shrink-0 btn-square bg-secondary rounded-circle"
                    style={{ width: "50px", height: "50px" }}
                  >
                    <i class="fa fa-phone text-white"></i>
                  </div>
                  <div class="ms-3">
                    <h4 class="text-primary">Call Us</h4>
                    <Link class="h5" href="tel:+0123456789" target="_blank">
                      + 0141-4084426
                    </Link>
                  </div>
                </div>
              </div>
              <div class="col-xl-4 col-lg-6 wow fadeIn" data-wow-delay=".7s">
                <div class="d-flex bg-light p-3 rounded">
                  <div
                    class="flex-shrink-0 btn-square bg-secondary rounded-circle"
                    style={{ width: "50px", height: "50px" }}
                  >
                    <i class="fa fa-envelope text-white"></i>
                  </div>
                  <div class="ms-3">
                    <h4 class="text-primary">Email Us</h4>
                    <Link
                      class="h5"
                      href="mailto:hrteam@digitaladdworld.in"
                      target="_blank"
                    >
                      {" "}
                      hrteam@digitaladdworld.in{" "}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div class="row g-5">
              <div class="col-lg-6 wow fadeIn" data-wow-delay=".3s">
                <div class="p-3 h-100 rounded contact-map">
                  <iframe
                    class="rounded w-100 h-100"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3559.1178606767877!2d75.79047297401118!3d26.850811064344878!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x396db6f7b0f3c5d7%3A0x713d5b96f9bb61cf!2sA-124%2C%20Near%20Sanganer%20Road%2C%20Dadu%20Dayal%20Nagar%2C%20Kalyanpura%2C%20Sanganer%2C%20Jaipur%2C%20Rajasthan%20302020!5e0!3m2!1sen!2sin!4v1696517425785!5m2!1sen!2sin"
                    style={{ border: "0" }}
                    allowfullscreen=""
                    loading="lazy"
                    referrerpolicy="no-referrer-when-downgrade"
                  ></iframe>
                </div>
              </div>
              <div class="col-lg-6 wow fadeIn" data-wow-delay=".5s">
                <div class="p-3 rounded contact-form">
                  <div class="mb-4">
                    <input
                      type="text"
                      class="form-control border-0 py-3"
                      placeholder="Your Name"
                    />
                  </div>
                  <div class="mb-4">
                    <input
                      type="email"
                      class="form-control border-0 py-3"
                      placeholder="Your Email"
                    />
                  </div>
                  <div class="mb-4">
                    <input
                      type="text"
                      class="form-control border-0 py-3"
                      placeholder="Project"
                    />
                  </div>
                  <div class="mb-4">
                    <textarea
                      class="w-100 form-control border-0 py-3"
                      rows="6"
                      cols="10"
                      placeholder="Message"
                    ></textarea>
                  </div>
                  <div class="text-start">
                    <button
                      class="btn bg-primary text-white py-3 px-3"
                      type="button"
                    >
                      Send Message
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Contact End --> */}

      {/* <!-- Back to Top --> */}
      <button
        className="btn btn-secondary btn-square rounded-circle back-to-top"
        onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
      >
        <i className="fa fa-arrow-up text-white"></i>
      </button>
    </>
  );
};

export default Home;
