import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const Service = () => {
  const [showMore1, setShowMore1] = useState(false);
  const [showMore2, setShowMore2] = useState(false);
  const [showMore3, setShowMore3] = useState(false);
  const [showMore4, setShowMore4] = useState(false);
  const [showMore5, setShowMore5] = useState(false);
  const [showMore6, setShowMore6] = useState(false);

  // Function to toggle the state
  const toggleDetails1 = () => {
    setShowMore1(!showMore1);
  };
  const toggleDetails2 = () => {
    setShowMore2(!showMore2);
  };
  const toggleDetails3 = () => {
    setShowMore3(!showMore3);
  };
  const toggleDetails4 = () => {
    setShowMore4(!showMore4);
  };
  const toggleDetails5 = () => {
    setShowMore5(!showMore5);
  };
  const toggleDetails6 = () => {
    setShowMore6(!showMore6);
  };

  return (
    <>
      {/* <!-- Page Header Start --> */}
      <div class="container-fluid page-header py-5">
        <div class="container text-center py-5">
          <h1 class="display-2 text-white mb-4 animated slideInDown">
            Services
          </h1>
          <nav aria-label="breadcrumb animated slideInDown">
            <ol class="breadcrumb justify-content-center mb-0">
              <li class="breadcrumb-item">
                <Link to="/">Home</Link>
              </li>
              <li class="breadcrumb-item">
                <Link to="/service">Pages</Link>
              </li>
              <li class="breadcrumb-item" aria-current="page">
                Services
              </li>
            </ol>
          </nav>
        </div>
      </div>
      {/* <!-- Page Header End --> */}

      {/* <!-- Fact Start --> */}
      <div class="container-fluid bg-secondary py-5">
        <div class="container">
          <div class="row">
            <div class="col-lg-3 wow fadeIn" data-wow-delay=".1s">
              <div class="d-flex counter">
                <h1 class="me-3 text-primary counter-value">99</h1>
                <h5 class="text-white mt-1">
                  Success in getting happy customer
                </h5>
              </div>
            </div>
            <div class="col-lg-3 wow fadeIn" data-wow-delay=".3s">
              <div class="d-flex counter">
                <h1 class="me-3 text-primary counter-value">25</h1>
                <h5 class="text-white mt-1">
                  Thousands of successful business
                </h5>
              </div>
            </div>
            <div class="col-lg-3 wow fadeIn" data-wow-delay=".5s">
              <div class="d-flex counter">
                <h1 class="me-3 text-primary counter-value">120</h1>
                <h5 class="text-white mt-1">Total clients who love HighTech</h5>
              </div>
            </div>
            <div class="col-lg-3 wow fadeIn" data-wow-delay=".7s">
              <div class="d-flex counter">
                <h1 class="me-3 text-primary counter-value">5</h1>
                <h5 class="text-white mt-1">
                  Stars reviews given by satisfied clients
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Fact End --> */}

      {/* <!-- Services Start --> */}
      <div class="container-fluid services py-5 mb-5">
        <div class="container">
          <div
            class="text-center mx-auto pb-5 wow fadeIn"
            data-wow-delay=".3s"
            style={{ maxWidth: "600px" }}
          >
            <h5 class="text-primary">Our Services</h5>
            <h1>Services Built Specifically For Your Business</h1>
          </div>
          <div class="row g-5 services-inner">
            <div class="col-md-6 col-lg-4 wow fadeIn" data-wow-delay=".3s">
              <div class="services-item bg-light">
                <div class="p-4 text-center services-content">
                  <div class="services-content-icon">
                    <i class="fa fa-code fa-7x mb-4 text-primary"></i>
                    <h4 class="mb-3">Web Design</h4>

                    <p class="mb-4">
                      We create visually stunning, user-friendly web designs
                      that capture your brand's essence. Our designs focus on
                      aesthetics, functionality, and delivering an engaging
                      experience for your audience.
                    </p>

                    {showMore1 && (
                      <p className="mb-4">
                        Web application development refers to the process of
                        creating software applications that are designed to be
                        accessed and used through web browsers. These
                        applications run on web servers and are delivered to
                        users online. Web applications can provide various
                        functionalities and services, from simple web pages to
                        complex, interactive platforms.
                      </p>
                    )}

                    {/* Button to toggle the visibility of the second paragraph */}
                    <Link
                      to="javascript:void(0)"
                      className="btn btn-secondary rounded-pill px-5 py-3 text-white"
                      onClick={toggleDetails1}
                    >
                      {showMore1 ? "Show Less" : "More Details"}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-4 wow fadeIn" data-wow-delay=".5s">
              <div class="services-item bg-light">
                <div class="p-4 text-center services-content">
                  <div class="services-content-icon">
                    <i class="fa fa-file-code fa-7x mb-4 text-primary"></i>
                    <h4 class="mb-3">Web Development</h4>
                    <p class="mb-4">
                      We build responsive, high-performance websites that
                      deliver seamless user experiences across all devices. Our
                      custom web development solutions are tailored to meet your
                      business goals and drive online success.
                    </p>

                    {showMore2 && (
                      <p className="mb-4">
                        Development & Integration: Our skilled developers
                        transform the design into a fully functional website
                        using modern technologies like HTML5, CSS3, JavaScript,
                        and frameworks such as React. We also integrate
                        third-party tools, APIs, and services as needed.
                        <br></br>
                        Testing & Quality Assurance: Before launching, we
                        conduct extensive testing to ensure the site functions
                        flawlessly across all devices and browsers. This
                        includes testing for usability, performance, and
                        security.
                      </p>
                    )}

                    {/* Button to toggle the visibility of the second paragraph */}
                    <Link
                      to="javascript:void(0)"
                      className="btn btn-secondary rounded-pill px-5 py-3 text-white"
                      onClick={toggleDetails2}
                    >
                      {showMore2 ? "Show Less" : "More Details"}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-4 wow fadeIn" data-wow-delay=".7s">
              <div class="services-item bg-light">
                <div class="p-4 text-center services-content">
                  <div class="services-content-icon">
                    <i class="fa fa-external-link-alt fa-7x mb-4 text-primary"></i>
                    <h4 class="mb-3">App Development</h4>
                    <p class="mb-4">
                      We create high-performance, feature-rich mobile apps
                      tailored to your business needs, ensuring seamless
                      functionality across all platforms. From concept to
                      deployment, our team delivers innovative solutions that
                      engage and empower users.
                    </p>

                    {showMore3 && (
                      <p className="mb-4">
                        At DigitaladdworldTech, we develop high-performance,
                        feature-rich mobile apps tailored to meet your business
                        goals. Our apps are designed for seamless functionality
                        across iOS and Android platforms, providing an intuitive
                        user experience that drives engagement. From initial
                        concept and prototyping to full deployment, we use the
                        latest technologies to deliver innovative, scalable, and
                        secure solutions. Whether you need an app for
                        e-commerce, social networking, or enterprise management,
                        our team ensures it’s built to maximize user
                        satisfaction and help your business thrive in the
                        mobile-first world.
                      </p>
                    )}

                    {/* Button to toggle the visibility of the second paragraph */}
                    <Link
                      to="javascript:void(0)"
                      className="btn btn-secondary rounded-pill px-5 py-3 text-white"
                      onClick={toggleDetails3}
                    >
                      {showMore3 ? "Show Less" : "More Details"}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-4 wow fadeIn" data-wow-delay=".3s">
              <div class="services-item bg-light">
                <div class="p-4 text-center services-content">
                  <div class="services-content-icon">
                    <i class="fas fa-user-secret fa-7x mb-4 text-primary"></i>
                    <h4 class="mb-3">Web Security</h4>
                    <p class="mb-4">
                      We provide comprehensive web security solutions that
                      protect your website from cyber threats, ensuring data
                      integrity and confidentiality. Our services include
                      encryption, vulnerability assessments, and real-time
                      monitoring to safeguard your digital assets.
                    </p>

                    {showMore4 && (
                      <p className="mb-4">
                        At <b> DigitaladdworldTech </b>, we deliver
                        comprehensive web security solutions to protect your
                        website and digital assets from a wide range of cyber
                        threats. Our services include advanced encryption,
                        firewalls, and secure coding practices to safeguard
                        sensitive data. We conduct thorough vulnerability
                        assessments and penetration testing to identify
                        potential risks, ensuring your site is fortified against
                        attacks. With real-time monitoring and automated threat
                        detection, we respond swiftly to suspicious activity,
                        maintaining the integrity and confidentiality of your
                        data. Trust us to keep your online presence secure and
                        resilient against evolving cyber threats.
                      </p>
                    )}

                    {/* Button to toggle the visibility of the second paragraph */}
                    <Link
                      to="javascript:void(0)"
                      className="btn btn-secondary rounded-pill px-5 py-3 text-white"
                      onClick={toggleDetails4}
                    >
                      {showMore4 ? "Show Less" : "More Details"}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-4 wow fadeIn" data-wow-delay=".5s">
              <div class="services-item bg-light">
                <div class="p-4 text-center services-content">
                  <div class="services-content-icon">
                    <i class="fa fa-envelope-open fa-7x mb-4 text-primary"></i>
                    <h4 class="mb-3">Digital Marketing</h4>
                    <p class="mb-4">
                      Our digital marketing services help you reach your target
                      audience and grow your online presence through SEO, social
                      media, and paid advertising strategies. We create
                      data-driven campaigns designed to boost engagement and
                      drive conversions.
                    </p>
                    {showMore5 && (
                      <p className="mb-4">
                        At <b> DigitaladdworldTech </b>, we offer comprehensive
                        digital marketing solutions to help you reach your
                        target audience and grow your brand online. Our services
                        include SEO, social media marketing, and paid
                        advertising to enhance your visibility across all
                        channels. Using a data-driven approach, we craft
                        personalized campaigns that not only increase website
                        traffic but also boost engagement and conversions. From
                        keyword research and content creation to social media
                        management and PPC, we focus on strategies that deliver
                        measurable results and drive sustainable business
                        growth. Let us help you stay ahead in the competitive
                        digital landscape.
                      </p>
                    )}

                    {/* Button to toggle the visibility of the second paragraph */}
                    <Link
                      to="javascript:void(0)"
                      className="btn btn-secondary rounded-pill px-5 py-3 text-white"
                      onClick={toggleDetails5}
                    >
                      {showMore5 ? "Show Less" : "More Details"}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-4 wow fadeIn" data-wow-delay=".7s">
              <div class="services-item bg-light">
                <div class="p-4 text-center services-content">
                  <div class="services-content-icon">
                    <i class="fas fa-laptop fa-7x mb-4 text-primary"></i>
                    <h4 class="mb-3">Programming</h4>
                    <p class="mb-4">
                      We offer expert programming services, developing efficient
                      and scalable code tailored to your project needs. Our team
                      is proficient in multiple languages and frameworks,
                      ensuring robust solutions for web, mobile, and software
                      development.
                    </p>

                    {showMore6 && (
                      <p className="mb-4">
                        At <b> DigitaladdworldTech </b>, we provide expert
                        programming services to develop efficient, scalable, and
                        customized code that meets your project requirements.
                        Our team is skilled in a wide range of programming
                        languages, including Python, Java, C++, JavaScript, and
                        more, as well as frameworks like React, and Django. We
                        focus on building robust and secure solutions for web,
                        mobile, and software applications, ensuring seamless
                        performance and scalability. Whether it's developing
                        APIs, full-stack web apps, or complex software systems,
                        we deliver high-quality code that drives success and
                        supports long-term growth.
                      </p>
                    )}

                    {/* Button to toggle the visibility of the second paragraph */}
                    <Link
                      to="javascript:void(0)"
                      className="btn btn-secondary rounded-pill px-5 py-3 text-white"
                      onClick={toggleDetails6}
                    >
                      {showMore6 ? "Show Less" : "More Details"}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Services End -->/ */}

      {/* <!-- Back to Top --> */}
      <button
        className="btn btn-secondary btn-square rounded-circle back-to-top"
        onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
      >
        <i className="fa fa-arrow-up text-white"></i>
      </button>
    </>
  );
};

export default Service;
