import OwlCarousel from "react-owl-carousel";
import React, { useEffect, useState } from "react";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel";
import { Link } from "react-router-dom";

const About = () => {
  const [showMore, setShowMore] = useState(false);

  // Function to toggle the state
  const toggleDetails = () => {
    setShowMore(!showMore);
  };
  return (
    <>
      {/* <!-- Page Header Start --> */}
      <div class="container-fluid page-header py-5">
        <div class="container text-center py-5">
          <h1 class="display-2 text-white mb-4 animated slideInDown">
            About Us
          </h1>
          <nav aria-label="breadcrumb animated slideInDown">
            <ol class="breadcrumb justify-content-center mb-0">
              <li class="breadcrumb-item">
                <Link to="/">Home</Link>
              </li>
              <li class="breadcrumb-item">
                <Link to="/about">Pages</Link>
              </li>
              <li class="breadcrumb-item" aria-current="page">
                About
              </li>
            </ol>
          </nav>
        </div>
      </div>
      {/* <!-- Page Header End --> */}

      {/* <!-- Fact Start --> */}
      <div class="container-fluid bg-secondary py-5">
        <div class="container">
          <div class="row">
            <div class="col-lg-3 wow fadeIn" data-wow-delay=".1s">
              <div class="d-flex counter">
                <h1 class="me-3 text-primary counter-value">99</h1>
                <h5 class="text-white mt-1">
                  Success in getting happy customer
                </h5>
              </div>
            </div>
            <div class="col-lg-3 wow fadeIn" data-wow-delay=".3s">
              <div class="d-flex counter">
                <h1 class="me-3 text-primary counter-value">25</h1>
                <h5 class="text-white mt-1">
                  Thousands of successful business
                </h5>
              </div>
            </div>
            <div class="col-lg-3 wow fadeIn" data-wow-delay=".5s">
              <div class="d-flex counter">
                <h1 class="me-3 text-primary counter-value">120</h1>
                <h5 class="text-white mt-1">
                  Total clients who love digitaladdworldTech
                </h5>
              </div>
            </div>
            <div class="col-lg-3 wow fadeIn" data-wow-delay=".7s">
              <div class="d-flex counter">
                <h1 class="me-3 text-primary counter-value">5</h1>
                <h5 class="text-white mt-1">
                  Stars reviews given by satisfied clients
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Fact End --> */}

      {/* <!-- About Start --> */}
      <div class="container-fluid py-5 my-5">
        <div class="container py-5">
          <div class="row g-5">
            <div
              class="col-lg-5 col-md-6 col-sm-12 wow fadeIn"
              data-wow-delay=".3s"
            >
              <div class="h-100 position-relative">
                <img
                  src="img/about-1.jpg"
                  class="img-fluid w-75 rounded"
                  alt=""
                  style={{ marginBottom: "25%" }}
                />
                <div
                  class="position-absolute w-75"
                  style={{ top: "25%", left: "25%" }}
                >
                  <img
                    src="img/about-2.jpg"
                    class="img-fluid w-100 rounded"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div
              class="col-lg-7 col-md-6 col-sm-12 wow fadeIn"
              data-wow-delay=".5s"
            >
              <h5 class="text-primary">About Us</h5>
              <h1 class="mb-4">
                About digitaladdworldTech Agency And It's Innovative IT
                Solutions
              </h1>
              <p>
                <h5> Greetings from Digitaladdworld Tech! </h5>
                <br></br>
                <hr></hr>
                We at <b>Digitaladdworld Tech </b>are proud to say that we are a
                top-notch digital marketing agency fully committed to providing
                innovative IT Solutions. Even though our quest commenced on{" "}
                <b>January 16, 2023, </b> we have quite quickly become a go-to
                agency for the businesses that need support in the elevation of
                their online presence. Our highly motivated team comprises 15
                professionals who have different skills in the field of
                marketing and are masters of SEO, creating content, managing
                social networks, and shall also do PPC. Creativity is married to
                statistics for the betterment of the bottom line.
                <spacer></spacer>
                <em>
                  {" "}
                  <strong> Our goal is very clear : - </strong> to help every
                  organization succeed in the internet space. We appreciate that
                  every brand is faced with a different problem and as such
                  there is no one fit all solution. Be it a fresh business or
                  well rooted company, the world of digital marketing can be
                  overwhelming and we are ready to provide assistance.{" "}
                </em>{" "}
                <br></br>
              </p>
              {showMore && (
                <p className="mb-4">
                  We pride ourselves on our ability to innovate and stay ahead
                  of industry trends. Whether it's implementing advanced digital
                  transformation solutions or optimizing IT infrastructure, we
                  are committed to offering scalable, secure, and efficient
                  services. At digitaladdworldTech Agency, our clients are our
                  top priority, and we work closely with them to ensure every
                  solution we provide drives measurable results and adds value
                  to their operations. With a proven track record of success
                  across various industries, digitaladdworldTech Agency is more
                  than just a service provider—we are a trusted partner in our
                  clients' growth journey. Our approach combines technical
                  excellence, a deep understanding of the latest technologies,
                  and a customer-first mindset, ensuring we consistently deliver
                  high-performance solutions. At digitaladdworldTech Agency, we
                  are dedicated to delivering cutting-edge IT solutions that
                  empower businesses to thrive in the digital age. With a team
                  of experienced professionals, we specialize in a wide array of
                  services, including software development, cloud solutions,
                  cybersecurity, and IT consulting.<br></br>
                  <em>
                    {" "}
                    Our mission is to help organizations unlock their full
                    potential by providing tailored technology strategies that
                    align with their business goals.
                  </em>{" "}
                  <br></br>
                  At <strong> digitaladdworldTech </strong> Agency, we are
                  dedicated to delivering cutting-edge IT solutions that empower
                  businesses to thrive in the digital age. With a team of
                  experienced professionals, we specialize in a wide array of
                  services, including software development, cloud solutions,
                  cybersecurity, and IT consulting. Our mission is to help
                  organizations unlock their full potential by providing
                  tailored technology strategies that align with their business
                  goals.
                </p>
              )}

              {/* Button to toggle the visibility of the second paragraph */}
              <Link
                to="javascript:void(0)"
                className="btn btn-secondary rounded-pill px-5 py-3 text-white"
                onClick={toggleDetails}
              >
                {showMore ? "Show Less" : "More Details"}
              </Link>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- About End --> */}

      {/* <!-- Team Start --> */}
      <div className="container-fluid py-5 mb-5 team">
        <div className="container">
          <div
            className="text-center mx-auto pb-5 wow fadeIn"
            data-wow-delay=".3s"
            style={{ maxWidth: "600px" }}
          >
            <h5 className="text-primary">Our Team</h5>
            <h1>Meet our expert Team</h1>
          </div>

          {/* OwlCarousel for team members */}
          <OwlCarousel
            className="owl-carousel team-carousel wow fadeIn"
            loop
            margin={10}
            nav
            autoplay={true}
            autoplayTimeout={3000}
            smartSpeed={1000}
            responsive={{
              0: {
                items: 1, // 1 item on mobile screens
              },
              600: {
                items: 2, // 2 items on tablet
              },
              1000: {
                items: 3, // 3 items on desktop
              },
            }}
          >
            {/* Team Member 1 */}
            <div className="rounded team-item">
              <div className="team-content">
                <div className="team-img-icon">
                  <div className="team-img rounded-circle">
                    <img
                      src="img/team-2.jpg"
                      className="img-fluid w-100 rounded-circle"
                      alt=""
                    />
                  </div>
                  <div className="team-name text-center py-3">
                    <h4>Manish Sharma</h4>
                    <p className="m-0">MERN STACK DEVELOPER</p>
                  </div>
                  <div className="team-icon d-flex justify-content-center pb-4">
                    <Link
                      className="btn btn-square btn-secondary text-white rounded-circle m-1"
                      to=""
                    >
                      <i className="fab fa-facebook-f"></i>
                    </Link>
                    <Link
                      className="btn btn-square btn-secondary text-white rounded-circle m-1"
                      to=""
                    >
                      <i className="fab fa-twitter"></i>
                    </Link>
                    <Link
                      className="btn btn-square btn-secondary text-white rounded-circle m-1"
                      to=""
                    >
                      <i className="fab fa-instagram"></i>
                    </Link>
                    <Link
                      className="btn btn-square btn-secondary text-white rounded-circle m-1"
                      to=""
                    >
                      <i className="fab fa-linkedin-in"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            {/* Team Member 3 */}
            <div className="rounded team-item">
              <div className="team-content">
                <div className="team-img-icon">
                  <div className="team-img rounded-circle">
                    <img
                      src="img/team-1.jpg"
                      className="img-fluid w-100 rounded-circle"
                      alt=""
                    />
                  </div>
                  <div className="team-name text-center py-3">
                    <h4>TANISHA JAIN</h4>
                    <p className="m-0">DIGITAL MARKETING</p>
                  </div>
                  <div className="team-icon d-flex justify-content-center pb-4">
                    <Link
                      className="btn btn-square btn-secondary text-white rounded-circle m-1"
                      to=""
                    >
                      <i className="fab fa-facebook-f"></i>
                    </Link>
                    <Link
                      className="btn btn-square btn-secondary text-white rounded-circle m-1"
                      to=""
                    >
                      <i className="fab fa-twitter"></i>
                    </Link>
                    <Link
                      className="btn btn-square btn-secondary text-white rounded-circle m-1"
                      to=""
                    >
                      <i className="fab fa-instagram"></i>
                    </Link>
                    <Link
                      className="btn btn-square btn-secondary text-white rounded-circle m-1"
                      to=""
                    >
                      <i className="fab fa-linkedin-in"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            {/* Team Member 4 */}
            <div className="rounded team-item">
              <div className="team-content">
                <div className="team-img-icon">
                  <div className="team-img rounded-circle">
                    <img
                      src="img/team-1.jpg"
                      className="img-fluid w-100 rounded-circle"
                      alt=""
                    />
                  </div>
                  <div className="team-name text-center py-3">
                    <h4>SHALINI SHARMA </h4>
                    <p className="m-0">FULL STACK TRANEE</p>
                  </div>
                  <div className="team-icon d-flex justify-content-center pb-4">
                    <Link
                      className="btn btn-square btn-secondary text-white rounded-circle m-1"
                      to=""
                    >
                      <i className="fab fa-facebook-f"></i>
                    </Link>
                    <Link
                      className="btn btn-square btn-secondary text-white rounded-circle m-1"
                      to=""
                    >
                      <i className="fab fa-twitter"></i>
                    </Link>
                    <Link
                      className="btn btn-square btn-secondary text-white rounded-circle m-1"
                      to=""
                    >
                      <i className="fab fa-instagram"></i>
                    </Link>
                    <Link
                      className="btn btn-square btn-secondary text-white rounded-circle m-1"
                      to=""
                    >
                      <i className="fab fa-linkedin-in"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </OwlCarousel>
        </div>
      </div>
      {/* <!-- Team End --> */}

      {/* <!-- Back to Top --> */}
      <button
        className="btn btn-secondary btn-square rounded-circle back-to-top"
        onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
      >
        <i className="fa fa-arrow-up text-white"></i>
      </button>
    </>
  );
};

export default About;
