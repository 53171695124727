import './App.css';
import {
    BrowserRouter as Router,
    Link,
    Route,
    Routes,
    Navigate,
    useNavigate,
  } from "react-router-dom";

import Home from './component/Home';
import About from './component/About'
import ErrorPage from './component/404';
import Blog from './component/Blog';
import Contact from './component/Contact';
import Project from './component/Project';
import Service from './component/Service';
import Team from './component/Team';
import Testimonial from './component/Testimonial';
import Header from './component/common/Header';
import Footer from './component/common/Footer';

function App() {

return(<>
<Router>
<Header/>
<Routes>
    <Route path = '/' element= {<Home/>}/>
    <Route path = '/about' element= {<About/>}/>
    <Route path = '/*' element= {<ErrorPage/>}/>
    <Route path = '/blog' element= {<Blog/>}/>
    <Route path = '/contact' element= {<Contact/>}/>
    <Route path = '/project' element= {<Project/>}/>
    <Route path = '/service' element= {<Service/>}/>
    <Route path = '/team' element= {<Team/>}/>
    <Route path = '/testimonial' element= {<Testimonial/>}/>
</Routes>
<Footer/>
</Router>


</>)
}

export default App;
