import { Link, NavLink } from "react-router-dom";
const Header = () => {
  return (
    <>
      {/* <!-- Topbar Start --> */}
      <div class="container-fluid bg-dark py-2 d-none d-md-flex">
        <div class="container">
          <div class="d-flex justify-content-between topbar">
            <div class="top-info">
              <small class="me-3 text-white-50">
                <Link to="javascript:void(0)">
                  <i class="fas fa-map-marker-alt me-2 text-secondary"></i>
                </Link>
                Sanganer, Jaipur-302020, Rajasthan{" "}
              </small>
              <small class="me-3 text-white-50">
                <Link to="javascript:void(0)">
                  <i class="fas fa-envelope me-2 text-secondary"></i>
                </Link>
                hrteam@digitaladdworld.in
              </small>
            </div>
            <div id="note" class="text-secondary d-none d-xl-flex ">
              <small>Note : We help you to Grow your Business</small>
            </div>
            <div class="top-link top-hover">
               <Link to="https://www.facebook.com/profile.php?id=61551668379454"
                class="bg-light nav-fill btn btn-sm-square rounded-circle"
              >
                <i class="fab fa-facebook-f text-primary"></i>
              </Link>
               <Link to=""
                class="bg-light nav-fill btn btn-sm-square rounded-circle"
              >
                <i class="fab fa-twitter text-primary "></i>
              </Link>

               <Link to=""
                class="bg-light nav-fill btn btn-sm-square rounded-circle"
              >
                <i class="fab fa-instagram text-primary"></i>
              </Link>
               <Link to=""
                class="bg-light nav-fill btn btn-sm-square rounded-circle me-0"
              >
                <i class="fab fa-linkedin-in text-primary"></i>
              </Link>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Topbar End --> */}

      {/* <!-- Navbar Start --> */}
      <div class="container-fluid bg-primary">
        <div class="container">
          <nav class="navbar navbar-dark navbar-expand-lg py-0">
            <Link to="/" class="navbar-brand">
              <h1 class="text-white fw-bold d-block">
                Digital<span class="text-secondary">add</span>world
                <span class="text-secondary">Tech</span>{" "}
              </h1>
            </Link>
            <button
              type="button"
              class="navbar-toggler me-0"
              data-bs-toggle="collapse"
              data-bs-target="#navbarCollapse"
            >
              <span class="navbar-toggler-icon"></span>
            </button>
            <div
              class="collapse navbar-collapse bg-transparent"
              id="navbarCollapse"
            >
              <div class="navbar-nav ms-auto mx-xl-auto p-0">
                <NavLink
                  to="/"
                  exact
                  className="nav-item nav-link"
                  activeClassName="active"
                >
                  Home
                </NavLink>
                <NavLink
                  to="/about"
                  exact
                  className="nav-item nav-link"
                  activeClassName="active"
                >
                  About
                </NavLink>{" "}
                <NavLink
                  to="/service"
                  exact
                  className="nav-item nav-link"
                  activeClassName="active"
                >
                  Services
                </NavLink>{" "}
                <NavLink
                  to="/project"
                  exact
                  className="nav-item nav-link"
                  activeClassName="active"
                >
                  Projects
                </NavLink>
                <NavLink
                  to="/team"
                  exact
                  className="nav-item nav-link"
                  activeClassName="active"
                >
                  Our Team
                </NavLink>
                <NavLink
                  to="/contact"
                  exact
                  className="nav-item nav-link"
                  activeClassName="active"
                >
                  Contact
                </NavLink>
              </div>
            </div>
            <div class="d-none d-xl-flex flex-shirink-0 ">
              <div
                id="phone-tada"
                class="d-flex align-items-center justify-content-center me-4"
              >
                <Link
                  to="tel:+ 0141-4084426"
                  class="position-relative animated tada infinite"
                >
                  <i class="fa fa-phone-alt text-white fa-2x"></i>
                  <div
                    class="position-absolute"
                    style={{ top: "-7px", left: "20px" }}
                  >
                    <span>
                      <i class="fa fa-comment-dots text-secondary"></i>
                    </span>
                  </div>
                </Link>
              </div>
              <div class="d-flex flex-column">
                <span class="text-white-50">Have any questions?</span>
                <span class="text-secondary">Call: + 0141-4084426</span>
              </div>
            </div>
          </nav>
        </div>
      </div>
      {/* <!-- Navbar End --> */}
    </>
  );
};
export default Header;
